import { useEffect } from 'react';
import { getItem, setItem } from '@/helpers/session-storage';
import { useGetPersonsQuery } from '@/store/queries/person';
import { startChat } from './useChat.helper';

const storageKeyProactiveChatFlag = 'myphx-proactive-chat-enabled';
const getProactiveChatSessionObject = () => getItem(storageKeyProactiveChatFlag);
const setProactiveChatSessionStorageIfNeeded = (hasProactiveChat: boolean) => {
  if (!getProactiveChatSessionObject()) {
    setItem(storageKeyProactiveChatFlag, hasProactiveChat.toString());
  }
};

export type ProactiveChatProperties = {
  personId?: string;
  firstName?: string;
  lastName?: string;
  profileId?: string;
  irn?: string;
  email?: string;
  phoneNumber?: string;
};

export const useProactiveChat = ({
  personId,
  firstName,
  lastName,
  profileId,
  irn,
  email,
  phoneNumber,
}: ProactiveChatProperties) => {
  const { data: { userDefined: userDefinedAttributes } = {} } = useGetPersonsQuery({
    personId,
  });

  useEffect(() => {
    const proactiveChatCategory = userDefinedAttributes
      ?.find?.((a: string) => a?.includes('ProactiveChat'))
      ?.replace('ProactiveChat', '') || '';

    const hasProactiveChat = (proactiveChatCategory || '') !== '';
    // set only once only when it is true
    if (hasProactiveChat) {
      setProactiveChatSessionStorageIfNeeded(hasProactiveChat);
    }

    const proactiveChatFlag = getProactiveChatSessionObject();
    const shouldInitiateChat = [firstName, lastName, email, profileId, irn, proactiveChatCategory]
      .every(
        (item) => (item || '') !== '',
      ) && proactiveChatFlag === 'true';

    let startChatTimeOutCallId: NodeJS.Timeout;

    if (shouldInitiateChat) {
      startChatTimeOutCallId = setTimeout(() => {
        startChat({
          firstName,
          lastName,
          email,
          profileId,
          studentIRN: irn,
          phoneNumber,
          proactiveChatCategory,
        });
        setItem(storageKeyProactiveChatFlag, 'false');
      }, 5000);
    }

    return () => {
      if (startChatTimeOutCallId) {
        clearTimeout(startChatTimeOutCallId);
      }
    };
  }, [
    firstName,
    lastName,
    email,
    phoneNumber,
    irn,
    profileId,
    userDefinedAttributes,
  ]);
};

export default useProactiveChat;
