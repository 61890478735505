const URL_ENCODED_PIPE_CHAR = '%7C';

export const getAllCookies = () => {
  if (typeof document === 'undefined') {
    return new Map();
  }
  const cookieData = document.cookie.split(';').map((cookie) => {
    const trimmed = (cookie || '').trim();
    const key = trimmed.substring(0, trimmed.indexOf('='));
    const value = trimmed.substring(trimmed.indexOf('=') + 1);
    return [key, value];
  });
  return new Map(cookieData);
};

export const getMcidFromCookie = () => {
  const cookies = getAllCookies();
  const mcidCookie = cookies.get('s_ecid');
  const [, mcid] = mcidCookie?.split(URL_ENCODED_PIPE_CHAR) || [];
  return mcid || '';
};

export const getIpAddressFromCookie = () => {
  const cookies = getAllCookies();
  return cookies.get('remote-address');
};
